@use "reset";

* {
	box-sizing: border-box;
}

.toggle-preview {
	display: inline;
	background: transparent;
	color: inherit;
	border: none;
	cursor: pointer;
}

#preview {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	border: 1px solid black;
	background-color: white;
}

body {
	font-size: 12pt;
	background-color: #ddd;
}

h1 {
	font-size: 140%;
	margin-bottom: 1rem;
}

.search {
	form {
		margin-bottom: 1rem;
		padding: 1rem;
		border-bottom: 1px solid black;
	}

	.fulltext input {
		font-size: 150%;
		font-family: inherit;
		width: 100%;
	}

	form > p {
		margin-bottom: 0.5rem;
	}
}

.result-list {
	padding: 1rem;
	counter-reset: searchResults;

	& > li {
		margin-bottom: 1rem;
		counter-increment: searchResults;
	}

	.parties {
		margin-bottom: 0.5rem;

		& > li {
			display: block;
		}
	}
	.part-name {
		font-weight: bold;
	}
}

.result-title {
	font-weight: bold;
	font-size: 120%;
	margin-bottom: 0.5rem;

	&:before {
		content: counter(searchResults) ". ";
		width: 3rem;
	}
}

.result-details {
	margin-left: 2rem;
	margin-right: 1rem;

	.result-files, .result-themes {
		& > ul {
			&, li {
				display: inline;
			}

			li::after {
				content: " :: ";
			}
		}
	}
}

.not-available {
	color: #888;
}

.App {
	margin: 1rem;
	border: 1px solid #444;
	background-color: white;
}

.result-paginate {
	background-color: #eee;
	height: 3rem;
	width: 100%;
	display: flex;
	align-items: center;
	user-select: none;

	li {
		display: inline;
		margin-left: 0.5rem;
		margin-right: 0.5rem;
		width: 2rem;
		height: 2rem;
		text-align: center;

		a {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			display: block;
			width: 100%;
			height: 2rem;
		}
	}

	li:not(.disabled), li:not(.break) {
		cursor: pointer;

		&:hover {
			background-color: #ddd;
		}
	}

	.previous, .next {
		flex: 1 0;
	}
	.previous {
		text-align: left;
	}
	.next {
		text-align: right;
	}
	.selected {
		background-color: #ccc;
	}
	.disabled {
		color: #999;
	}
}
